.login,
.register {
  min-height: 80vh;
  position: relative;

  .logo {
    margin-bottom: 2rem;
  }

  form {
    width: 100%;
  }

  .register-box,
  .login-box {
    padding: 4rem 3rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 22vw;
    max-width: 20rem;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      color: #666;
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }

    .input-control {
      margin-bottom: 1.5rem;
      width: 100%;

      label {
        margin-bottom: 1rem;
        display: inline-block;
        font-size: 1rem;
        color: #666;
        font-weight: 600;
      }

      input {
        display: block;
        box-sizing: border-box;
        // padding: 0.8rem;
        padding: 0.8rem 0.2rem;
        width: 100%;
        border: 1px solid #666;
      }
    }

    input[type="submit"] {
      border: none;
      background-color: rgb(233, 130, 147);
      color: #f3f3f3;
      padding: 0.7rem 1rem;
      font-size: 1.1rem;
      cursor: pointer;
    }
  }
}
