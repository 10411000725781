.main-content {
  // select vehicle category styles
  .select-category {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .select-category-text {
      color: #4f4f4f;
      font-weight: 600;
      margin-bottom: 2rem;
      text-align: center;
    }

    .vehicle-icons {
      display: flex;
      justify-content: center;

      .each-type {
        cursor: pointer;
        font-size: 4rem;
        color: rgb(233, 130, 147);
        margin-left: 1rem;
        margin-right: 1rem;
        position: relative;
        overflow: hidden;

        .type-text {
          font-size: 1.7rem;
          font-weight: 600;
          color: #202020;
          text-align: center;
        }

        // custom-category radio styels
        .custom-radio {
          position: absolute;
          top: 35%;
          left: 50%;
          transform: scale(11, 9);
          opacity: 0;
          cursor: pointer;
          // width: 45px;
        }
      }
    }
  }

  // add new vehicle btn styles
  .add-new-vehicle {
    .btn {
      cursor: pointer;
      border: 3px solid rgb(70, 175, 255);
      display: flex;
      padding: 0.2rem 1rem;
      width: 30%;
      margin-left: auto;
      border-radius: 1rem;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 500;

      .pop-up-trigger {
        display: flex;
      }

      .icon {
        display: flex;
        font-size: 1.5rem;
        color: rgb(233, 130, 147);
        margin-right: 1rem;
      }
    }
  }
  .update-sorting-btn {
    cursor: pointer;
    padding: 15px 20px;
    // margin: 40px;
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    border: none;
    background: #202020;
    color: #fff;
    font-weight: bold;
  }

  // vehicle id enterence styles
  .vehicle-id-form {
    margin-top: 2rem;
    display: flex;
    justify-content: center;

    .form {
      display: flex;

      .title-text {
        display: flex;
        flex-direction: column;
        align-items: center;

        .bold {
          font-size: 1.8rem;
          font-weight: 600;
          color: #202020;
        }

        .normal {
          font-size: 1rem;
          font-weight: 600;
          color: #202020;
        }
      }

      .input {
        padding-left: 2rem;
        input {
          padding: 0.8rem 2rem;
          border-radius: 1rem;
          border: none;
          border: 2px solid gray;
          color: rgb(233, 130, 147);
          font-size: 1.2rem;
          font-weight: 600;
          outline: none;

          &::placeholder {
            color: rgb(233, 130, 147);
          }
        }
      }
    }

    .error-msg {
      color: rgb(233, 130, 147);
      font-size: 1.4rem;
      text-transform: uppercase;
      text-align: center;
      margin-top: 0.5rem;
    }
  }

  // images area styles
  .images {
    max-width: 1000px;
    margin: auto;
    margin-top: 2rem;

    .vehicle-img {
      object-fit: cover;
    }

    .top {
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        span {
          display: flex;
          font-size: 2rem;
          color: rgb(233, 130, 147);
        }

        a {
          text-decoration: underline;
          font-weight: 700;
          color: gray;
          font-size: 1.2rem;
        }
      }

      .right {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #202020;
        font-size: 1rem;
        padding-right: 15.5rem;
      }
    }

    .bottom-wrapper {
      display: flex;
      position: relative;

      .finished {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .bold-text {
          font-size: 1.5rem;
          font-weight: 800;
          color: #202020;
          margin-left: 1rem;
        }
      }
    }

    .imgs-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .close {
        display: flex;
        justify-content: flex-end;
        font-size: 1.7rem;
        color: #202020;
        padding-right: 0.5rem;
      }

      .delete-spinner {
        display: inline-block;
        border: 2px solid #f3f3f3; /* Light grey */
        border-top: 2px solid #202020; /* Blue */
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        animation: spin 2s linear infinite;
      }

      .each-img {
        position: relative;
        cursor: pointer;
        flex-basis: 16rem;
        border: 2px solid rgb(233, 130, 147);
        width: 15rem;
        text-align: center;
        padding-bottom: 1rem;
        margin: 0.5rem;
        height: 10rem;
        min-height: 10rem;
        overflow: hidden;

        .video-icon {
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 4rem;
          color: rgb(233, 130, 147);
        }

        img {
          width: 15rem;
          object-fit: initial;
          height: 8rem;
        }

        .fileUploader {
          position: absolute;
          background-color: rgba(255, 255, 255, 0);
          top: 50%;

          .fileContainer {
            padding: 0;
            margin: 0;
            // position: absolute;
            opacity: 0;
          }

          .btn-full-size {
            margin: 0;
            transform: scale(7, 13);
            opacity: 1;
          }
        }
      }

      .add-more {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 16rem;
        color: #4f4f4f;
        padding-bottom: 0rem !important;
        overflow: hidden;

        .icon {
          font-size: 3rem;
          display: flex;
        }
        .model-trigger {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transform: scale(5, 10);
          opacity: 0;
          cursor: pointer;
        }
      }
    }
  }
}

// keyframe for delete icon
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
