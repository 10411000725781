.dzu-dropzone {
  &.custom-dropzone-style {
    height: 100%;
    width: 50vw;
    max-width: 800px;
    overflow-x: hidden;
    margin-top: 2rem;
    padding-top: 3rem;

    .input-label-style {
      padding: 2rem;
      text-align: center;
    }
  }

  .dzu-previewButton {
    display: none;
  }

  .dzu-inputLabelWithFiles {
    position: absolute;
    top: -1rem;
    left: 0rem;
  }

  .dzu-submitButtonContainer {
    position: absolute;
    top: -1.3rem;
    right: 1rem;
  }
}
