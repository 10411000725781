* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: "Roboto", sans-serif;
}
a,
a:link {
  color: #202020;
  text-decoration: none;
}

html {
  font-size: 15px;
}

.App {
  padding: 2rem;
}
