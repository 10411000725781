.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logout-wrapper {
    display: flex;
    align-items: center;

    .icon {
      color: rgb(70, 175, 255);
      font-size: 1.3rem;
    }

    .logout-text {
      display: inline-block;
      font-size: 1rem;
      font-weight: 600;
    }

    .btn {
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}
